body {
  background-color: whitesmoke;
  overflow-x: hidden;
}

.info-container {
  padding: 0.8rem;
  margin: 0.6rem;
  justify-content: space-around;
  background-color: white;
}

.logo {
  width: 300px;
}

.secondary {
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
}

.info-code {
  color: rgba(0, 0, 0, 1);
  font-weight: 300;
  font-size: 1.5rem;
}

.info-name {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 300;
}

.info-value {
  color: blue;
  font-weight: bold;
  font-size: 1.5rem;
}

.span-detail-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 1.5rem;
}

.span-detail-text {
  color: rgba(0, 0, 0, 0.65);
  font-size: 1.2rem;
  margin-top: 10%;
}

.img-container {
  display: flex;
  justify-content: center;
}

.img-zoom {
  width: 150px;
  padding: 0.5rem;
  transition: transform 0.2s;
}

.img-zoom:hover {
  transform: scale(1.5); /* (150% zoom) */
}
