// 1. Import the initial variables
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";

// 2. Setup your Custom Colors

$yellow: #ffc107;
$yellow-invert: findColorInvert($yellow);
$blue: #2d2b87;
$blue-invert: findColorInvert($blue);
$orange-300: #fbd38d;
$orange-400: #f6ad55;
$orange-200: #feebc8;
$gray-200: #edf2f7;
$gray-100: #f7fafc;

// 3. Add new color variables to the color map.
@import "~bulma/sass/utilities/derived-variables.sass";

$addColors: (
  "yellow": (
    $yellow,
    $yellow-invert
  ),
  "blue": (
    $blue,
    $blue-invert
  )
);

$colors: map-merge($colors, $addColors);
