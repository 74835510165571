// Start styles in form
@import "../../styles/colors.scss";

.login-wrapper {
  padding-bottom: 3em;
}

.login * {
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.login {
  position: relative;
  top: 50%;
  width: 380px;
  display: table;
  margin: -150px auto 0 auto;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  border-radius: 4px;
  background: #fff;
  .legend {
    position: relative;
    width: 100%;
    display: block;
    background: $yellow;
    padding: 15px;
    color: #fff;
    font-size: 20px;
    // margin: 0.5em auto;
    font-weight: bold;
    &:after {
      content: "";
      background-image: url(../../images/logo_transparent.png);
      background-size: 200px 75px;
      background-repeat: no-repeat;
      background-position: 182px -16px;
      opacity: 0.09;
      top: 0.1em;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }

  .login-input {
    position: relative;
    width: 90%;
    margin: 2.2rem auto;
    background-color: white;
    span {
      position: absolute;
      display: block;
      color: darken(#ededed, 10%);
      left: 10px;
      top: 0.2em;
      font-size: 20px;
      /* Animation */
      opacity: 1;
      &.focus {
        opacity: 0;
        transition: opacity 500ms ease-out;
      }
    }

    input {
      width: 100%;
      padding: 10px 5px 10px 40px;
      display: block;
      transition: 0.2s ease-out;
      border: 0;
      background-color: hsl(220, 12%, 95%);
      font-weight: 600;
      &:focus {
        padding: 10px 5px 10px 10px;
        outline: 0;
        border-color: #ff7052;
      }
    }
  }

  .submit,
  .pb-button {
    width: 45px;
    height: 45px;
    display: block;
    margin: 0 auto -15px auto;
    background: #fff;
    border-radius: 100%;
    border: 1px solid $yellow;
    color: $yellow;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0px 0px 0px 7px #fff;
    transition: 0.2s ease-out;

    &:hover,
    &:focus {
      background: $yellow;
      color: #fff;
      outline: 0;
    }
  }

  .login-btn {
    width: 90%;
    height: 2.25em;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    font-size: 16px;
    padding: 0;
  }
}

.feedback {
  position: absolute;
  bottom: -70px;
  width: 100%;
  text-align: center;
  color: #fff;
  background: #2ecc71;
  padding: 10px 0;
  font-size: 12px;
  display: none;
  opacity: 0;

  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(46, 204, 113, 0);
    border-bottom-color: #2ecc71;
    border-width: 10px;
    margin-left: -10px;
  }
}
