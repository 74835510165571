body {
  /* Without this, the body has excess horizontal scroll when the menu is open */
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.logo {
  max-height: 76px !important;
}

.main-search .icon {
  vertical-align: middle;
  height: 100% !important;
}

.is-vertical-center {
  display: flex;
  align-items: center;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: whitesmoke;
}

.login-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 100vh;
  background-color: whitesmoke;
  background: url(../../images/concrete-texture.png) repeat fixed;
}

section.content-wrapper {
  display: flex;
  margin-top: 0rem;
  min-height: 100vh;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}
*,
::after,
::before {
  box-sizing: inherit;
}

.main-content {
  padding: 0 1rem;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

div.main-content.container {
  margin-left: 16px;
  margin-right: 16px;
}

.menu-text {
  padding-left: 0.5em;
}

.menu-icon {
  vertical-align: middle;
  width: 16px !important;
}

.menu-trigger {
  margin-left: 1em;
  font-size: 1.2rem;
}

.menu-trigger .icon {
  vertical-align: middle;
  height: 100% !important;
}
