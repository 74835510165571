@import "~antd/dist/antd.css";

@import "./colors.scss";

/**
 To override bulma's font family with antd's font family.
*/
$family-primary: "font-family: -apple-system", "BlinkMacSystemFont", "Segoe UI",
  "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue",
  "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";

@import "~bulma/bulma";

@import "~bulma-extensions/bulma-tooltip/dist/bulma-tooltip";

:root {
  --white: #fff;
  --background-color: #f1f3f5;
  --gray-100: #f7fafc;
  --gray-200: #edf2f7;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --green: #00cc88;
  --green-50: #85ffd6;
  --green-100: #70ffcf;
  --green-200: #47ffc2;
  --green-300: #1fffb4;
  --green-400: #00f5a3;
  --green-500: #00cc88;
  --green-600: #009463;
  --green-700: #005c3d;
  --green-800: #002418;
  --green-900: #000000;
  --blue-50: #eff6ff;
  --blue-100: #dbeafe;
  --blue-200: #bfdbfe;
  --blue-300: #93c5fd;
  --blue-400: #60a5fa;
  --blue-500: #3b82f6;
  --blue-600: #2563eb;
  --page-section-border: 3px solid #ffc107;
  --page-section-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.ant-dropdown-menu {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
}

.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.875rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-gray-500 {
  color: #a0aec0;
}
.text-gray-600 {
  color: #718096;
}

.text-gray-700 {
  color: #4a5568;
}
.text-gray-900 {
  color: #1a202c;
}
.text-orange-300 {
  color: #fbd38d;
}
.text-orange-400 {
  color: #f6ad55;
}
.text-orange-500 {
  color: #ed8936;
}
.text-orange-600 {
  color: #dd6b20;
}
.text-orange-700 {
  color: #c05621;
}
.text-orange-800 {
  color: #9c4221;
}
.text-orange-900 {
  color: #7b341e;
}

.text-red-400 {
  color: #fc8181;
}
.text-red-500 {
  color: #f56565;
}
.text-red-600 {
  color: #e53e3e;
}
.text-red-700 {
  color: #c53030;
}
.bg-gray-100 {
  background-color: #f7fafc;
}
.bg-gray-200 {
  background-color: #edf2f7;
}
.bg-gray-300 {
  background-color: #e2e8f0;
}

.font-bold {
  font-weight: 700;
}

.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}
.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 0.75rem;
}
.m-4 {
  margin: 1rem;
}
.m-5 {
  margin: 1.25rem;
}
.m-6 {
  margin: 1.5rem;
}
.m-8 {
  margin: 2rem;
}
.m-1 {
  margin: 2.5rem;
}
.m-1 {
  margin: 3rem;
}
.m-1 {
  margin: 4rem;
}

.max-w-xs {
  max-width: 20rem;
}
.max-w-sm {
  max-width: 24rem;
}
.max-w-md {
  max-width: 28rem;
}

.w-8 {
  width: 2rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-8 {
  margin-bottom: 2rem;
}

.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.ml-8 {
  margin-left: 2rem;
}
.ml-10 {
  margin-left: 2.5rem !important;
}
.ml-12 {
  margin-left: 3rem;
}
.ml-16 {
  margin-left: 4rem;
}

/**
  Margin right
*/
.mr-4 {
  margin-right: 1rem;
}

.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-8 {
  margin-top: 2rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.p-4 {
  padding: 1rem 1rem;
}

.p-5 {
  padding: 1.25rem 1.25rem;
}
.p-6 {
  padding: 1.5rem 1.5rem;
}

.p-8 {
  padding: 2rem 2rem;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.uppercase {
  text-transform: uppercase;
}
.Box-divider--light-bottom-1 {
  box-shadow: inset 0 -1px #e3e8ee;
}
.Box-divider--light-vertical-1 {
  box-shadow: inset 0 -1px #e3e8ee, inset 0 1px #e3e8ee;
}
.Padding-vertical--20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.Padding-vertical--8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.container-sm {
  max-width: 640px;
}

.container-lg {
  max-width: 1024px;
}

.container-xl {
  max-width: 1280px;
}

.skeleton {
  --gray-light: #ececed;
  --gray-accent: #e9e9ea;
}

/* Shared placeholder styles. */
.skeleton--loading::before,
.skeleton--loading::after {
  content: "";
  display: block;
  background-image: linear-gradient(
    110deg,
    var(--gray-light) 73%,
    var(--gray-accent) 75%,
    var(--gray-light) 77%,
    var(--gray-light) 78%,
    var(--gray-accent) 84%,
    var(--gray-accent) 88%,
    var(--gray-light) 94%,
    var(--gray-light) 100%
  );
  background-size: 200% 100%;
  background-position: 0 center;
  border-radius: inherit;
  animation: 2s ease-in-out skeletonLoading infinite;
}

/* Image placeholder. */
.skeleton--loading::before {
  width: 100%;
  height: 200px;
}

/* Text placeholder. */
.skeleton--loading::after {
  animation-delay: 50ms;
  width: 92%;
  height: 1rem;
  margin-top: 0.75rem;
}

@keyframes skeletonLoading {
  0% {
    background-position-x: 0;
  }
  40%,
  100% {
    background-position-x: -200%;
  }
}
