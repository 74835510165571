.keyboard-shortcuts {
  border: 1px solid lightgrey;
  border-bottom: none;
  margin: 0 !important;
}

.keyboard-shortcuts ul {
  display: flex;
}

.keyboard-shortcuts ul li {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.keyboard-shortcuts .navbar-item,
.navbar-link {
  padding: 0.1rem;
}

.keyboard-shortcuts .key-name {
  border: 2px solid lightblue;
}

.keyboard-shortcuts .key-desc {
  border: none;
}
