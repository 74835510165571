.lin-navbar {
  display: flex;
}
.lin-navbar .lin-navbar-right {
  display: flex;
  min-width: 30px;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding-right: 0.2em;
  padding-left: 1em;
}

.lin-navbar .main-search {
  flex: 1;
  max-width: 655px;
}

.circle {
  line-height: 0; /* remove line-height */
  display: inline-block; /* circle wraps image */
  margin: 5px;
  border: 1px solid rgba(20, 150, 237, 0.7);
  border-radius: 50%; /* relative value */
  transition: linear 0.25s;
  cursor: pointer;
  box-shadow: 0 0 3px 2px rgba(20, 150, 237, 0.1);

}
.circle img {
  border-radius: 50%; /* relative value for
           adjustable image size */
  width: 2.3em;
  height: 2.3em;
}
.circle:hover {
  transition: ease-out 0.2s;
  -webkit-transition: ease-out 0.2s;
  box-shadow: 0 0 5px 5px rgba(20, 150, 237, 0.1);
}
a.circle {
  color: transparent;
} /* IE fix: removes blue border */

.triangle-wrapper {
  display: inline-block;
  vertical-align: middle;
  display: flex;
  justify-content: flex-end;
  margin-top: -0.2em;
  margin-right: 0.8em;
}

.triangle {
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-right-width: 8px;
  border-left-width: 8px;
  border-bottom: 8px solid #fff;
}

.shortcuts-trigger {
  display: flex;
  min-width: 30px;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding-right: 1em;
  padding-left: 0.2em;
}

.shortcuts-trigger a {
  color: rgba(20, 150, 237, 1);
}

.shortcuts-trigger a:hover {
  color: rgba(20, 150, 237, 0.9);
}


